import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Await, Link, useLocation } from "react-router-dom";
import axios, { Axios } from 'axios';
import { useQuery } from 'react-query';
import { useEffect } from 'react';
import { useState } from 'react';


const API_url ='https://api.sunshinemontessoriecr.in';


const AdmissionsModal = ({ open, onClose }) => {
  const [img,setImaegs] = useState(null);

  const getModalImage = async() =>{
    const res =await axios.get(`${API_url}/api/modals?populate=*`);
    return res.data.data;
  }
  const {data:Images} = useQuery('getModal', getModalImage);

      console.log('Modal IMages',Images);

  return (
<>
    
    <Modal open={open} onClose={onClose} >
   
    <Box
    className="admission-modal"
      sx={{
        display: 'flex',
        position: 'absolute',
        top: '50%',
        left: '50%',
        gap:'20px',
        transform: 'translate(-50%, -50%)',
      }}
    >
      
      {Images && Images.map((image) => {
            return (
              <img
                key={image.id}
                src={`${API_url}${image.attributes.ModalImage.data.attributes.url}`}
                alt="Admissions Open"
                className="admission-image"
                style={{
                  height: "auto",
                  border: "4px double #1976d2",
                  borderRadius: 20,
                }}
              />
            );
          })}    
    </Box>
    </Modal>

  </>
  );
};

export default AdmissionsModal;