import React, { useState } from 'react';
import Slider from 'react-slick';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Modal from 'react-modal';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Gallery.css';
import axios from 'axios';
import { useQuery } from 'react-query';

export const Gallery = () => {
  const API_url = 'https://api.sunshinemontessoriecr.in';

  const getGalleryImage = async () => {
    const res = await axios.get(`${API_url}/api/galleries?populate=*`);
    return res.data.data;
  };
  const { data: Images } = useQuery('getModal', getGalleryImage);
  console.log("Gallery", Images);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalImages, setModalImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const openModal = (images, index) => {
    const formattedImages = images?.map((image) => ({
      original: `${API_url}${image.attributes.url}`,
      thumbnail: `${API_url}${image.attributes.url}`
    }));
    setModalImages(formattedImages);
    setCurrentImageIndex(index);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const slickSettings = {
    dots: true,
    infinite: true,
    speed: 1,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div id="gallery">
      <div className="section-title" style={{ marginTop: "70px" }}>
        <h2 style={{ textAlign: "center" }}>Albums</h2>
        <h3 style={{ textAlign: "center" }}>Moments of Sunshine</h3>
      </div>
      <div className="main-gallery-container" style={{ marginBottom: "50px" }}>
        {Images?.map((event, index) => (
          <div key={`flexbox-item-${index}`} className="flexbox-item">
            <div className="card" onClick={() => openModal(event?.attributes?.Image?.data, 0)}>
              <img src={`${API_url}/${event?.attributes?.Image?.data[0]?.attributes.url}`} alt={event?.attributes?.Title} className="card-image" />
              <div className="card-title">{event?.attributes?.Title}</div>
            </div>
          </div>
        ))}
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Image Modal"
        className="modal-container"
        overlayClassName="modal-overlay"
      >
        <IconButton className="close-button" onClick={closeModal}>
          <CloseIcon />
        </IconButton>
        <ImageGallery items={modalImages} startIndex={currentImageIndex} />
      </Modal>
    </div>
  );
};
